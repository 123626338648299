.App {
  background: #FAFBFF;
  text-align: center;
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 5rem;
    max-width: 300px;
    text-align: left;

    .error {
      margin: 0;
      color: red;
    }
    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;

        .option {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1rem 0;
          margin-right: 1rem;
          padding-inline: 1rem;
          height: 40px;
          width: fit-content;
          border: none;
          border-radius: 5px;
          box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
          cursor: pointer;
          transition: 0.2s all ease-in-out;

          &:hover {
            opacity: 0.7;
          }
          
          &.active {
            border: 1px solid green;
          }
  
        }

        svg {
          fill: green;
          font-size: 2rem;
        }
      }
    }

    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 1rem;
      width: 100%;
    }

    button {
      cursor: pointer;
      padding: 1rem 1.5rem;
      width: 100px;
      background: #6733bf;
      border-radius: 50px;
      border: none;
      color: #fff;
      text-align: left;
      transition: 0.2s all ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}